<script setup>
/* eslint-disable no-irregular-whitespace */
defineProps({
    data: {
        type: Object,
        default: () => ({}),
    },
});

const splitVisible = ref(false);

const addIconToLastLine = (splitTextInstance, str) => {
    splitTextInstance.lines[splitTextInstance.lines.length - 1].insertAdjacentHTML('beforeend', `<span class="inline-block mb-2 split-visible:translate-y-0 translate-y-[115%] delay-500 transition duration-1000">${str}</span>`);
};

</script>
<template>
    <Parallax
        :enter="false"
        :leave="false"
        :animation="false"
        :enter-leave-wrapper-classes="{
            'my-30 px-7 lg:flex lg:gap-12 lg:px-17':true,
            'items-center': data.imageMask == 'imageEnTriangle',
            'mask-right': data.imagePosition === 'right',
            'mask-left': data.imagePosition === 'left'
        }"
    >
        <div
            v-if="data.imageMask == 'imageEnTriangle'"
            class="relative transform-gpu drop-shadow-2xl lg:w-1/2"
            :class="[
                {'-right-11 order-2 lg:-right-17': data.imagePosition === 'right'},
                {'-left-11 order-1 lg:-left-17': data.imagePosition === 'left'}
            ]"
        >
            <div v-if="data?.image?.[0]?.url" class="svg-clipped">
                <div aria-hidden class="gradient absolute left-0 top-0 z-11 size-full mix-blend-hard-light" />
                <ParallaxChild class="relative z-10 aspect-[3/4]" enter-leave-wrapper-classes="absolute z-0 inset-0 w-full h-full">
                    <img
                        class="absolute inset-0 z-10 block size-full object-cover grayscale"
                        :style="{
                            'object-position': data?.image?.[0]?.focalPoint
                                ? `${data?.image?.[0]?.focalPoint[0] * 100}% ${data?.image?.[0]?.focalPoint[1] * 100}%`
                                : '50% 50%',

                        }"
                        :src="data.image[0].url"
                        :srcset="data.image[0].srcset"
                        :alt="data.image[0].alt"
                    >
                </ParallaxChild>
            </div>
        </div>
        <div
            v-else
            class="relative"
            :class="[
                {'order-2': data.imagePosition === 'right'},
                {'order-1': data.imagePosition === 'left'},
                {'lg:w-1/2': data.forRealisations != 'dansUneRealisation'},
                {'lg:w-2/3': data.forRealisations == 'dansUneRealisation'}
            ]"
        >
            <div
                v-if="data?.image?.[0]?.url"
                class="relative mb-7 aspect-[7/6] overflow-hidden rounded shadow-2xl"
            >
                <ParallaxChild
                    :animation="{ transform: ['translateY(-15%) translateZ(0)', 'translateY(15%) translateZ(0)'] }"
                    class="size-full"
                    enter-leave-wrapper-classes="size-full"
                >
                    <img
                        class="absolute inset-0 block size-full scale-[1.15] object-cover"
                        :style="{
                            'object-position': data.image[0].focalPoint
                                ? `${data.image[0].focalPoint[0] * 100}% ${data.image[0].focalPoint[1] * 100}%`
                                : '50% 50%',

                        }"
                        :src="data.image[0].url"
                        :srcset="data.image[0].srcset"
                        :alt="data.image[0].alt"
                    >
                </ParallaxChild>
            </div>
            <div
                v-if="data.image[0].alt"
                class="-mt-2 text-xxs uppercase text-cendre"
                v-html="data.image[0].alt"
            />
            <div v-if="data.titlePosition == 'avecImage'">
                <div v-if="data.sectionTitle" class="mb-6 text-5xl uppercase">
                    <h2>
                        <span v-html="parseMarkdown(data.sectionTitle)" />
                        <span
                            v-if="data?.icon?.inline"
                            class="ml-2 inline-block"
                            v-html="data.icon.inline"
                        />
                    </h2>
                    <h3
                        v-if="data.subtitle"
                        class="text-xl uppercase text-carmin lg:text-base"
                    >
                        <Parallax :animation="false" :leave="false">
                            <div v-html="parseMarkdown(data.subtitle)" />
                        </Parallax>
                    </h3>
                </div>
            </div>
        </div>

        <div
            v-if="data.textContent"
            class="py-9 text-lg lg:py-0 lg:text-2xl"
            :class="[
                {'order-1': data.imagePosition === 'right'},
                {'order-2': data.imagePosition === 'left'},
                {'lg:w-1/2': data.forRealisations != 'dansUneRealisation'},
                {'lg:w-1/3': data.forRealisations == 'dansUneRealisation'}
            ]"
        >
            <div v-if="data.titlePosition != 'avecImage'">
                <div v-if="data.sectionTitle" class="mb-6 text-5xl font-bold uppercase">
                    <h2 class="svg:ml-2 svg:inline-block">
                        <SplitText
                            :animation="false"
                            :class="{
                                'split-visible': splitVisible
                            }"
                            v-on:after-split="addIconToLastLine($event, ' '+data.icon.inline)"
                            v-on:enter="splitVisible = true"
                            v-on:leave="splitVisible = false"
                        >
                            <span v-html="parseMarkdown(data.sectionTitle)" />
                        </SplitText>
                    </h2>
                    <h3
                        v-if="data.subtitle"
                        class="text-xl uppercase text-carmin lg:text-base"
                    >
                        <Parallax :animation="false" :leave="false">
                            <div v-html="parseMarkdown(data.subtitle)" />
                        </Parallax>
                    </h3>
                </div>
            </div>

            <ParallaxChild
                v-if="data.textContent"
                :animation="false"
                :enter-options="{delay:0.15}"
                :class="[
                    {'lg:flex lg:h-full lg:items-center': data.forRealisations == 'dansUneRealisation'}
                ]"
            >
                <div
                    class="wysiwyg mb-2 w-full font-serif text-lg lg:w-3/4"
                    v-html="data.textContent"
                />
            </ParallaxChild>
            <ParallaxChild
                :animation="false"
                :enter-options="{delay:0.3}"
            >
                <Button
                    v-for="link in data.links"
                    :key="link.id"
                    :to="link.url"
                >
                    <span v-html="link.linkText" />
                    <span class="ml-2 inline-block fill-current" v-html="link.icon.inline" />
                </Button>
            </ParallaxChild>
        </div>
    </Parallax>
</template>
